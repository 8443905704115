import React, { useRef, useState } from 'react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CAlert
} from '@coreui/react';
import { useLoaderData } from 'react-router-dom';
import axios from "axios";
import { Editor } from '@tinymce/tinymce-react';

const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

export async function LoaderOptionUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/options/' + params.optionId, header).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}
const OptionForm = () => {
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [key, setKey] = useState((data != undefined) ? data.optionKey : '');
  const [value, setValue] = useState((data != undefined) ? data.value : '');
  const [validated, setValidated] = useState(false)
  const [visibleSuccess, setVisibleSuccess] = useState(false)
  const [visibleError, setVisibleError] = useState(false)
  const editorRef = useRef(null);
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  const handleSubmit = event => {
    event.preventDefault();
    let v = false;
    let success = false;
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      v = true;
    }
    setValidated(true);


    if (v) {
      const article = {
        name: name,
        optionKey: key,
        value: value,
        data: ''
      };
      if (editorRef.current) {
        let d = editorRef.current.getContent();
        if (d != '') {
          article.data = d;
        }
      }

      if (data != undefined) {
        const response = axios.put(process.env.REACT_APP_SERVER_URL + '/api/options/' + data.id, article, header).then((response) => {
          console.log(response);
          if (response.statusText == 'OK')
            setVisibleSuccess(true)
          else
            setVisibleError(true)
        });

      } else {
        const response = axios.post(process.env.REACT_APP_SERVER_URL + '/api/options', article, header).then((response) => {
          console.log(response);
          if (response.statusText == 'Created')
            setVisibleSuccess(true)
          else
            setVisibleError(true)
        });
      }
    }

  }


  return (
    <>
      <CAlert color="success" dismissible visible={visibleSuccess} onClose={() => setVisibleSuccess(false)}>Дані збережено!</CAlert>
      <CAlert color="danger" dismissible visible={visibleError} onClose={() => setVisibleError(false)}>Дані не збережено!</CAlert>
      <CForm onSubmit={handleSubmit}
        className="row g-3 needs-validation"
        noValidate
        validated={validated}
      >
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} feedbackValid="" autoComplete="off" required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Ключ</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={key} onChange={(e) => setKey(e.target.value)} feedbackValid="" autoComplete="off" required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Значення</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={value} onChange={(e) => setValue(e.target.value)} feedbackValid="" autoComplete="off" required />
        </div>
        <div className="mb-3">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.data : ''}
            init={{
              height: 500,
              // menubar: false,
              menubar: 'edit view insert format tools',
              // plugins: [
              //   'advlist autolink lists link image charmap print preview anchor',
              //   'searchreplace visualblocks code fullscreen',
              //   'insertdatetime media table paste code help wordcount'
              // ],
              plugins: [
                'advlist', 'autolink',
                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'code'
              ],
              // toolbar: 'undo redo | formatselect | ' +
              //   'bold italic backcolor | alignleft aligncenter ' +
              //   'alignright alignjustify | bullist numlist outdent indent | ' +
              //   'removeformat | help',
              toolbar: 'undo redo | blocks fontname fontfamily fontsize | bold italic underline strikethrough superscript subscript | ' +
                'backcolor forecolor |' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | table help',
              font_size_formats: '10px 12px 14px 16px 18px 24px 36px 48px 72px',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div>
        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>
    </>
  );

}

export default OptionForm;