// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usefulMaterials-table__th1, .usefulMaterials-table__td1 {
    /* text-align: center; */
    width: 140px;
}

.usefulMaterials-table__th2, .usefulMaterials-table__td2 {
    width: 45%;
}`, "",{"version":3,"sources":["webpack://./src/styles/useful-materials.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,YAAY;AAChB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".usefulMaterials-table__th1, .usefulMaterials-table__td1 {\n    /* text-align: center; */\n    width: 140px;\n}\n\n.usefulMaterials-table__th2, .usefulMaterials-table__td2 {\n    width: 45%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
