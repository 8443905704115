/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormCheck, CFormTextarea, } from '@coreui/react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import WarningWhenEditing from 'src/components/product/WarningWhenEditing';

const urlApi_1 = '/faqs';
const urlRouter = '/useful-materials';

export async function LoaderUsefulMaterialUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi_1}/${params.pid}`).then((response) => {
      // console.log("get /api/products/ response", response);
      return response;
    });
    return res;
  }
  return false;
};

// Форма для "Корисні матеріали"
const UsefulMaterialForm = () => {
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [myData, setMyData] = useState((data != undefined) ? data : false);
  // const userData = localStorage.getItem('user_data');
  const userData = JSON.parse(localStorage.getItem('user_data'));
  // JSON.parse(userData)

  const [name, setName] = useState((data != undefined) ? data.name : '');
  // const [dateCreated, setDateCreated] = useState((data != undefined) ? data.dateCreated : '');
  // const [userCreated, setUserCreated] = useState((data != undefined) ? data.userCreated : '');
  // const [dateUpdated, setDateUpdated] = useState((data != undefined) ? data.dateUpdated : '');
  // const [userUpdated, setUserUpdated] = useState((data != undefined) ? data.userUpdated : '');
  const [description, setDescription] = useState((data != undefined) ? data.contentFull : '');
  // const [userData, setUserData] = useState(false);

  // Відкрити/закрити вікно з попередженням про редагування товару
  const [openWarning, setOpenWarning] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);

  // Сьогоднішня дата
  const todayDate = new Date();
  const dateChanged = todayDate.toISOString().slice(0, 10);

  // const editorRef = useRef(null);

  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess = "Матеріал збережено";

  // // Дані залогованого користувача з localStprage
  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem('user_data'));
  //   setUserData(user);
  // }, []);

  // Функція для надсилання запиту
  const handleSubmit = event => {
    event.preventDefault();
    if (userData != false) {
      // if (editorRef.current) {
      // console.log(show);

      // Стартові параметри для запиту за відфільтрованими товарами
      let requestParams = {
        "name": name,
        "contentFull": description,
        "active": 'on',
        "date": todayDate
      };

      if (data != undefined) {
        // requestParams["dateModified"] = dateChanged;
        requestParams["dateModified"] = todayDate;
        requestParams["modifiedUser"] = `/api/users/${userData.id}`;
        // AxiosInstance.put(`${urlApi_1}/${data.id}`, requestParams, { params: ApiParams }).then((response) => {
        AxiosInstance.put(`${urlApi_1}/${data.id}`, requestParams).then((response) => {
          // console.log('put faqs response', response);
          if (response.status === 200) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextSuccess);
            // Оновити дані
            setMyData(response.data);
          }
        });
        // console.log('requestParams', requestParams);
      } else {
        // requestParams["dateEntered"] = dateChanged;
        requestParams["dateEntered"] = todayDate;
        requestParams["createdUser"] = `/api/users/${userData.id}`;
        AxiosInstance.post(`${urlApi_1}`, requestParams).then((response) => {
          // console.log('faq post response', response);
          // debugger;
          if (response.status === 201) {
            return navigate(`${urlRouter}/read/${response.data.id}`);
          }
        });
        // console.log('requestParams', requestParams);
      };
    } else {
      alert("Для збереження необхідно залогінитися");
    }
  };

  // Функція для закриття модального вікна з попередженням про редагування товару
  const handleCloseWarning = () => {
    // Закрити модальне з попередженням
    setOpenWarning(false);
  };

  // Функція для відміни введених даних
  const cansel = () => {
    setName(myData.name);
    setDescription(myData.contentFull);
  };

  // Відслідковувати чи показувати попередження
  useEffect(() => {
    // console.log('data', data);
    if (data != undefined) {
      setOpenWarning(true);
    }
  }, [data]);

  // Відслідковувати розблокування кнопки
  useEffect(() => {
    if (data == undefined && name != "") {
      setIsDisabledBtn(false);
    } else if (myData != false && (myData.name != name || myData.contentFull != description)) {
      setIsDisabledBtn(false);
    } else {
      setIsDisabledBtn(true);
    }
  }, [data, myData, name, description]);

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      {/* Модальне вікно з попередженням про редагування товару */}
      <WarningWhenEditing openWarning={openWarning} handleCloseWarning={handleCloseWarning}
        text="Ви зайшли в режим редагування корисного матеріалу" />

      <CForm onSubmit={handleSubmit} className="mb-3">
        {(data != undefined) && <h1 className="mb-3">{myData.name}</h1>}

        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва*</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Опис</CFormLabel>
          <CFormTextarea type="text" id="exampleFormControlInput1" rows={10} value={description} onChange={(e) => setDescription(e.target.value)} autoComplete="off" />
        </div>

        {/* <div className="mb-3">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.description : ''}
        init={{
                            height: 500,
                            // menubar: false,
                            menubar: 'edit view insert format tools',
                            // plugins: [
                            //   'advlist autolink lists link image charmap print preview anchor',
                            //   'searchreplace visualblocks code fullscreen',
                            //   'insertdatetime media table paste code help wordcount'
                            // ],
                            plugins: [
                                'advlist', 'autolink',
                                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'code'
                            ],
                            // toolbar: 'undo redo | formatselect | ' +
                            //   'bold italic backcolor | alignleft aligncenter ' +
                            //   'alignright alignjustify | bullist numlist outdent indent | ' +
                            //   'removeformat | help',
                            toolbar: 'undo redo | blocks fontname fontfamily fontsize | bold italic underline strikethrough superscript subscript | ' +
                                'backcolor forecolor |' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist checklist outdent indent | removeformat | table help',
                            font_size_formats: '10px 12px 14px 16px 18px 24px 36px 48px 72px',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
          />
        </div> */}

        <CButton type="submit" color="success" disabled={isDisabledBtn} title="Зберегти зміни">Зберегти</CButton>
        {
          (data != undefined) &&
          <CButton className="ms-3" color="secondary" disabled={isDisabledBtn} onClick={() => cansel()} title="Відмінити внесені зміни">Відмінити зміни</CButton>
        }
        <Link to="/useful-materials" className="ms-3"><CButton type="submit" color="secondary" title='Перейти на сторінку "Корисні матеріали"'>Корисні матеріали</CButton></Link>
      </CForm>

    </>
  );
};

export default UsefulMaterialForm;