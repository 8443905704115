import React, { useRef, useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';



const urlApi = '/storage_elements';
const urlRouter = '/storage-element';
const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}`, 'accept': 'application/json' } }


export async function LoaderStorageElementUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
};

const StorageElementForm = () => {
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [description, setDescription] = useState((data != undefined) ? data.description : '');
  const [c1id, setC1id] = useState((data != undefined) ? data.c1id : '');
  const [isMain, setIsMain] = useState((data != undefined) ? data.isMain : false);
  const [storage, setStorage] = useState((data != undefined) ? { label: data.storage.name, value: data.storage.id } : {});
  const editorRef = useRef(null);
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;
  // const [defaultAccountManager, setDefaultAccountManager] = useState({});

  // useEffect(() => {
  //   if(data = undefined)
  //     setStorage({label: result.storage.name, value: result.storage.id});
  // }, [data]);

  const loadOptionsAcc = async (inputValue) => {
    let rez = await AxiosInstance.get(`/storages?name=${inputValue}`, { headers: { 'accept': 'application/json' } }).then((response) => {
      return response.data.map((result) => ({
        label: result.name,
        value: result.id
      }));
    });
    // setDefaultAccountManager(rez);
    return rez;
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
      const data_response = {
        name: name,
        description: editorRef.current.getContent(),
        c1Id: c1id,
        isMain: isMain,
        storage: `/api/storages/${storage.value}`
      };
      if (data != undefined) {
        const response = AxiosInstance.put(`${urlApi}/${data.id}`, data_response, header).then((response) => {
          if (response.status === 200)
            return navigate(urlRouter);
        });
      } else {
        const response = AxiosInstance.post(`${urlApi}`, data_response, header).then((response) => {
          if (response.status === 201)
            return navigate(urlRouter);
        });
      }
    }
  };

  const handleChange = (value) => {
    setStorage(value);
  }

  return (
    <>
      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Склад</CFormLabel>
          <AsyncSelect
            cacheOptions
            loadOptions={loadOptionsAcc}
            value={storage}
            // onChange={handleChange}
            className="flex-grow-1"
            name="account"
            // defaultOptions={defaultAccountManager}
            onChange={(value) => { handleChange(value) }}
            styles={{ border: '1px solid red' }}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">1c код</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput5" value={c1id} onChange={(e) => setC1id(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <Form.Check type="checkbox" label="головний" defaultChecked={isMain} onChange={(e) => {
            setIsMain(!isMain)
          }} />
        </div>


        <div className="mb-3">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? description : ''}
            init={{
              height: 500,
              // menubar: false,
              menubar: 'edit view insert format tools',
              // plugins: [
              //   'advlist autolink lists link image charmap print preview anchor',
              //   'searchreplace visualblocks code fullscreen',
              //   'insertdatetime media table paste code help wordcount'
              // ],
              plugins: [
                'advlist', 'autolink',
                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'code'
              ],
              // toolbar: 'undo redo | formatselect | ' +
              //   'bold italic backcolor | alignleft aligncenter ' +
              //   'alignright alignjustify | bullist numlist outdent indent | ' +
              //   'removeformat | help',
              toolbar: 'undo redo | blocks fontname fontfamily fontsize | bold italic underline strikethrough superscript subscript | ' +
                'backcolor forecolor |' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | table help',
              font_size_formats: '10px 12px 14px 16px 18px 24px 36px 48px 72px',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div>

        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>
    </>
  );
};

export default StorageElementForm;