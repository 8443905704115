import React, { useEffect, useState } from 'react';
import { CForm, CRow, CCol, CButton } from '@coreui/react';
import axios from "axios";
import AxiosInstance from 'src/components/Axios';
import { confirmAlert } from 'react-confirm-alert'; // Import

const tokenStr = localStorage.getItem('user');

// Видалення зображення з продукту
const UpdateImageForm = ({ product_id, actionShowModal, media, UpdateMedia, setShowModalMedia, toastifySuccess, mainMedia, resultMedias }) => {
  // console.log("DeleteImgFrom media", media);

  const urlApi = '/products';
  const [validated, setValidated] = useState(false);
  const [sortOrder, setSortOrder] = useState({});

  useEffect(() => {
    if (media?.length > 0) {
      const initialSortOrder = resultMedias?.reduce((acc, obj) => {
        acc[obj.id] = obj.sort;
        return acc;
      }, {});
      setSortOrder(initialSortOrder);

    }
  }, [media, media.sort, resultMedias]);

  // Текст для popup
  const popupTextMediaSuccess = "Зображення оновлено";
  const tostifyError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };

  //керуваня інпутом
  const handleSortChange = (id, value) => {
    setSortOrder((prevState) => ({
      ...prevState,
      [id]: value
    }));
  };



  // Оновити порядок сортування


  const updateSortOrder = (id) => {

    // console.log('mainMedia', mainMedia);
    // console.log('id', id);

    const order = sortOrder[id];
    // const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects/update-order';
    const data = {
      sort: Number(order)
    };
    const config = {
      headers: {
        "Authorization": `Bearer ${tokenStr}`,
        "Content-Type": "application/json"
      }
    };
    AxiosInstance.put(`/media_objects/${id}`, data, config).then((response) => {
      console.log("Сортування оновлено", response.data);
      UpdateMedia();
      toastifySuccess(popupTextMediaSuccess);
    }).catch((error) => {
      tostifyError("Сортування не оновлено")
      console.error("Помилка оновлення порядку сортування", error);
    });
  };

  return (
    <>
      <CForm noValidate
      //  validated={validated}
      >
        <div className="my-4">
          {/* Виберіть зображення для видалення */}
          {/* Оновити порядок зображень */}
        </div>
        <div
          // className='d-flex justify-content-center'
          className='d-flex flex-column justify-content-start'
        >
          {
            ((media != '') &&
              // media.
              media?.length > 0) &&
            // [...media]
            //   .sort((a, b) => (a.sort || 0) - (b.sort || 0)).
            resultMedias.
              map(el => {
                // console.log(el)

                return (
                  <CRow className='mb-3' key={el.id}>
                    {/* <div key={el.id} className="me-2"> */}

                    <CCol xs={4} style={{ width: "30%" }}>
                      <img
                        className="me-3"
                        src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.contentUrl : ''}
                        style={{ height: '100px', width: 'auto', cursor: "pointer" }}
                      />
                    </CCol>
                    <CCol xs={4}>
                      <input
                        className="form-control form-control-lg"
                        id="formFileLg"
                        type="text"
                        placeholder="Порядок сортування"
                        value={sortOrder[el.id] || ''}
                        onChange={(e) => handleSortChange(el.id, e.target.value)}
                        autoComplete="off"
                      />
                    </CCol>
                    <CCol xs={4}>

                      <CButton color="warning" onClick={() => updateSortOrder(el.id)}
                        disabled={el.sort === Number(sortOrder[el.id])}
                      >
                        Оновити сортування

                      </CButton>
                    </CCol>


                    {/* </div> */}
                  </CRow>
                )
              })
          }
        </div>
      </CForm>
    </>
  );
}

export default UpdateImageForm;