import React, { useEffect, useState } from 'react'
import { CForm, CButton, CCol, CRow } from '@coreui/react'
import axios from 'axios'

const tokenStr = localStorage.getItem('user')

const MediaFrom = ({
  product_id,
  order_id,
  actionShowModal,
  user_id,
  UpdateMedia,
  toastifySuccess,
  UpdateTable,
  type,
  page,
  setNewMedia,
  setNewMediaId,
  category_id,

}) => {
  const [file, setFile] = React.useState()
  const [name, setName] = React.useState()
  const [validated, setValidated] = useState(false)
  // Текст для popup
  const popupTextMediaSuccess = 'Зображення додано'

  useEffect(() => {
    console.log('file', file)
  }, [file])

  const handleSubmit = (event) => {
    event.preventDefault()

    let v = false
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
    } else {
      v = true
    }
    setValidated(true)
    if (v) {
      const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects'
      const formData = new FormData()
      formData.append('file', file)
      formData.append('fileName', file?.name)
      formData.append('name', name ? name : '')
      if (product_id != undefined) formData.append('product', product_id)
      if (order_id != undefined) formData.append('orders', order_id)
      if (user_id != undefined) formData.append('user', user_id)
      if (category_id != undefined) formData.append('category', category_id)
      // formData.append('name', name);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${tokenStr}`,
        },
      }

      axios.post(url, formData, config).then((response) => {
        // console.log("post media response", response.data);
        if (actionShowModal !== undefined) {
          actionShowModal(false)
        }
        if (UpdateMedia != undefined) {
          UpdateMedia()
        }
        // Popup про успішні зміни
        toastifySuccess(popupTextMediaSuccess)
        // Для стоінки media/
        if (type === 'media') {
          // Оновити таблицю з зображеннями
          UpdateTable(page)
        }

        // Для сторінки site-products/update/id
        if (type === 'siteProducts') {
          // console.log('response.data', response.data);
          setNewMedia(response.data)
        }

        // Для сторінки category/update/id
        if (type === 'category') {
          // console.log('type === "category" response.data', response.data);
          setNewMediaId(response.data.id)

        }
      })
    }
  }
  function handleChange(event) {
    setFile(event.target.files[0])
  }

  return (
    <>
      <CForm onSubmit={handleSubmit} noValidate validated={validated}>
        <CRow>
          <CCol xs={4}>
            <input
              className="form-control form-control-lg"
              id="formFileLg"
              type="file"
              onChange={handleChange}
            />
          </CCol>
          <CCol xs={4}>
            <input
              className="form-control form-control-lg"
              id="formFileLg"
              type="text"
              placeholder="Назва"
              onChange={(e) => setName(e.target.value)}
              autoComplete="off"
            />
          </CCol>
          <CCol xs={4}>
            {/* <CButton type="submit" color="warning">Завантажити зображення</CButton> */}
            <CButton type="submit" color="warning" disabled={!file}>
              Завантажити зображення

            </CButton>
          </CCol>

        </CRow>
      </CForm>
    </>
  )
}

export default MediaFrom
