import React, { useEffect, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormCheck, CNav, CNavItem, CNavLink } from '@coreui/react';

// Вкладка "Наявність"
const ProductAvailability = (props) => {

    const [show, setShow] = useState((props.data != undefined && props.data.show != null) ? props.data.show : false);
    const [isTop, setIsTop] = useState((props.data != undefined && props.data.isTop != null) ? props.data.isTop : false);
    // const [isNew, setIsNew] = useState((props.data != undefined && props.data.isNew != null) ? props.data.isNew : false);
    const [balance, setBalance] = useState((props.data != undefined && props.data.balance != null) ? props.data.balance : 0);
    const [productToOrder, setProductToOrder] = useState((props.data != undefined) ? props.data.productToOrder : '0');
    const [minBalance, setMinBalance] = useState((props.data != undefined && props.data.minBalance != null) ? props.data.minBalance : 0)
    const [minBalanceAdmin, setMinBalanceAdmin] = useState((props.data != undefined && props.data.minBalanceAdmin != null) ? props.data.minBalanceAdmin : 0)

    // Для checkbox при ініціалазації
    // const initShow = (show != 1) ? false : true;
    // const initTop = (top != 1) ? false : true;
    // const [changeTop, setChangeTop] = useState(initTop);

    // Для зміни значення checkbox для поля "Хіти продаж"
    // useEffect(() => {
    //   changeTop ? setIsTop(1) : setIsTop(0)
    // }, [changeTop]);

    // Для checkbox при ініціалазації
    const initProductToOrder = (productToOrder != 1) ? false : true;
    const [changeProductToOrder, setChangeProductToOrder] = useState(initProductToOrder);
    const [finalArr, setFinalArr] = useState([])

    // Для зміни значення checkbox для поля "Товар під замовлення"
    useEffect(() => {
        changeProductToOrder ? setProductToOrder(1) : setProductToOrder(0)
    }, [changeProductToOrder]);

    useEffect(() => {

        props.setIsNew(prev => props.data.isNew)
    }, [props.data.isNew])

    const data_response = {
        isTop: isTop,
        isNew: props.isNew,
        show: show,
        balance: parseInt(balance),
        productToOrder: parseFloat(productToOrder),
        active: 'on',
        minBalance: parseInt(minBalance),
        minBalanceAdmin: parseInt(minBalanceAdmin),
        ... (((props.isNew && !props.data.isNew) || (!props.isNew && props.data.isNew)) ? { category: finalArr } : {})
    };

    useEffect(() => {
        let categoriesArr = [];
        const changedCategoryArr = props?.data?.category?.map(elem => elem.id) ?? []

        if (props.isNew && !props.data.isNew) {
            const isCategory55Present = changedCategoryArr.includes(55);
            if (!isCategory55Present) {
                categoriesArr = [...changedCategoryArr, 55]
            }
            else {
                categoriesArr = [...changedCategoryArr]
            }
        }
        else {
            if (!props.isNew
                && props?.data?.isNew
                && changedCategoryArr.includes(55)) {
                // console.log("видалити id")
                categoriesArr = changedCategoryArr.filter(id => id !== 55)
            }
            else {
                // console.log('не заходить в видалення id')
                categoriesArr = props.data.category?.map(elem => elem.id) ?? []
            }

        }

        // console.log("categoriesArr", categoriesArr)
        // console.log("changedCategoryArr", changedCategoryArr)
        setFinalArr(categoriesArr.map(id => `/api/categories/${id}`) ?? [])
    }, [props.isNew])
    // console.log("category", props.data.category)
    // console.log("props", props)
    // console.log('finalArr', finalArr)
    // console.log('isNew', isNew)
    return (
        <div>
            <CForm>
                <div className="mb-3">
                    <CFormCheck id="exampleFormControlInput8" label="Товар продається" checked={show} onChange={() => setShow(!show)} />
                </div>
                <div className="mb-3">
                    <CFormCheck id="exampleFormControlInput9" label="Топ продаж" checked={isTop} onChange={() => setIsTop(!isTop)} />
                </div>
                <div className="mb-3">
                    <CFormCheck id="exampleFormControlInput10" label="Новинка" checked={props.isNew} onChange={() => props.setIsNew(prev => !props.isNew)} />
                </div>
                <div className="mb-3">
                    <CFormCheck id="exampleFormControlInput12" label="Товар під замовлення" checked={initProductToOrder} onChange={() => setChangeProductToOrder(!changeProductToOrder)} />
                </div>

                <div className='row'>
                    <div className='col'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Баланс</CFormLabel>
                            <CFormInput type="number" id="exampleFormControlInput6" value={balance == null ? "" : balance} onChange={(e) => setBalance(e.target.value)} autoComplete="off" />
                        </div>
                    </div>
                    <div className='col'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Мінімальна кількість (сайт)</CFormLabel>
                            <CFormInput type="number" id="exampleFormControlInput6" value={minBalance == null ? "" : minBalance} onChange={(e) => setMinBalance(e.target.value)} autoComplete="off" />
                        </div>
                    </div>
                    <div className='col'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Мінімальна кількість  (інформувати в  боті) </CFormLabel>
                            <CFormInput type="number" id="exampleFormControlInput6" value={minBalanceAdmin == null ? "" : minBalanceAdmin} onChange={(e) => setMinBalanceAdmin(e.target.value)} autoComplete="off" />
                        </div>
                    </div>
                </div>
                <CButton color="success" onClick={() => props.handleSubmit(event, data_response)}>Зберегти</CButton>
            </CForm>
        </div>
    );
};

export default ProductAvailability;