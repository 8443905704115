/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useRef, useState, useEffect } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormCheck, CFormSelect } from '@coreui/react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetStorages } from './CreateFromStorageElements';
import BasicModal from '../Modal';
import AccountsFormComponents from '../accounts/AccountsFormComponents';
// import async from 'react-select/dist/declarations/src/async';

const urlApi = '/orders';
const urlApi_2 = '/account_addresses';

// Ключ для Editor
const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

const role = localStorage.getItem('user_role');
const userData = JSON.parse(localStorage.getItem('user_data'));

const status = [
    { value: 'is_forming', label: 'Формується' },
    { value: 'synced_in_1c', label: 'Чекає синхронізації в 1с' },
    { value: 'success_1c', label: 'Синхронізовано в 1с' },
    { value: 'comlated', label: 'Виконано' }
]
const paymentMethod = [
    // { value: 'paid', label: 'Оплачено' },
    // { value: 'before_payment', label: 'Перед оплата' },
    { value: 'cash', label: 'Готівка' },
    { value: 'bank', label: 'Банківський переказ' },
    { value: 'bank', label: 'Мульти оплата' },
    { value: 'kasa', label: 'Готівка' },
]
const curency = [
    // { value: 'paid', label: 'Оплачено' },
    // { value: 'before_payment', label: 'Перед оплата' },
    { value: 'uah', label: 'uah' },
    { value: 'usd', label: 'usd' },
    { value: 'eur', label: 'eur' }
]

const objOrderData = {
    // account: '',
    // agreement: '',
    status: 'is_forming',
    description: '',
    dateOrder: null,
    curency: 'uah',
    paymentMethod: 'cash',
    sumOrdered: 0,
    curencyRate: 1,
    discountTotal: 0,
    discountPercent: 0,
    // shippingAddressStreet: '',
    shippingAddressCity: '',
    // shippingAddressBuilding: '',
    // shippingAddressCountry: 'Україна',
    shippingAddressDate: '',
    synced: false,
    manager: ''
};

const FormOrders = (props) => {
    // console.log("formOrders props", props);

    const editorRef = useRef(null);
    const data = props.data;
    const navigate = useNavigate();
    const [values, setValues] = useState(objOrderData);
    const [selectItems, setSelectItems] = useState({
        // account: {},
        status: status[0],
        paymentMethod: paymentMethod[0],
        curency: curency[0],
        account: {
            label: (data != undefined && data.account != undefined) ? data.account.name : '',
            value: (data != undefined && data.account != undefined) ? data.account.id : '',
        },
        agreement: {
            label: (data != undefined && data.agreement != undefined) ? data.agreement.name : '',
            value: (data != undefined && data.agreement != undefined) ? data.agreement.id : '',
            // typePayment: result.typePayment
        },
    });

    // Нова адреса для контрагента
    const [newAddress, setNewAddress] = useState({ name: "" });

    // Адреса(и) вибраного контрагента
    const [address, setAddress] = useState("");
    // Вибрана адреса контрагента
    const [selectedAddress, setSelectedAddress] = useState("");
    // Напечатана адреса
    const [printedAddress, setPrintedAddress] = useState("");
    // Адреса, яка додасться до замовлення
    const [addressToOrder, setAddressToOrder] = useState("");
    // Ввести адресу вручну чи взяти з контрагента
    const [checked, setChecked] = useState(false);
    // Текст для popup
    const popupTextPostSuccess = "Замовлення створено";
    const popupTextPutSuccess = "Замовлення оновлено";

    // Створення контрагента в содальному вікні
    const [newAccountModal, setNewAccountModal] = useState("");
    const [newAgreementModal, setNewAgreementModal] = useState("");

    // Чи відкритий перший рівень модального вікна для уникнення put orders/id при створенні контрагента на другому рівні модального вікна, оскільки Form в середині Form і submit спрацьовує для двох форм
    const [isUpdateOrder, setIsUpdateOrder] = useState(true);

    // Функція для обнулення адрес
    const cleanAddress = () => {
        setChecked(false);
        setPrintedAddress("");
        setSelectedAddress("");
        setAddressToOrder("");
    };

    // Коли вибраний контрагент, то взяти в нього всі адреси
    useEffect(() => {
        // console.log('selectItems.account', selectItems.account);
        if (selectItems.account != undefined && selectItems.account.value != undefined) {
            AxiosInstance.get(`${urlApi_2}?account=${selectItems.account.value}`, { headers: { 'accept': 'application/json' } }).then((response) => {
                // console.log("response addresses", response);
                setAddress(response.data);
            });

            // Додати id контрагента для створення нової адреси для контрагента
            setNewAddress({
                ...newAddress,
                // account: `/api/accounts/${selectItems.account.value}`
            });

            // Обнулити адреси
            cleanAddress();

            // Обнулити введений договір
            setSelectItems({
                ...selectItems,
                agreement: {
                    label: '',
                    value: ''
                },
            });
        };
        if (selectItems.account != undefined && selectItems.account.value != undefined && selectItems.account.value != '') {
            // console.log("2222222222");
            // debugger
            loadOptionsAgree('');

            // Обнулити адреси
            cleanAddress();
        }
    }, [selectItems.account]);

    // Функція для вибору адреси з options
    const handleSelectChangeAddress = (event) => {
        const { value } = event.target.options[event.target.selectedIndex];
        setSelectedAddress({ value });
    };

    // Вибір адреси, яка додасться до замовлення
    useEffect(() => {
        checked ? setAddressToOrder(printedAddress) : setAddressToOrder(selectedAddress.value);
    }, [checked, selectedAddress, printedAddress]);

    // Для відправлення на сервер: Адреса контрагента
    useEffect(() => {
        setValues({
            ...values,
            shippingAddressCity: addressToOrder
        });
    }, [addressToOrder])

    // Відслідковувати зміну data
    useEffect(() => {
        if (data != null) {
            // console.log('data', data);

            // Для заповнення адреси
            if (data?.shippingAddressCity) {
                setChecked(true);
                setPrintedAddress(data.shippingAddressCity);
            };

            setValues({
                ...values,
                // account: `/api/accounts/${data.account.id}`,
                // agreement: (data.agreement != undefined) ? `/api/agreements/${data.agreement.id}` : '',
                status: data.status,
                description: data.description,
                dateOrder: data.dateOrder,
                curency: data.curency,
                paymentMethod: data.paymentMethod,
                sumOrdered: data.sumOrdered,
                curencyRate: data.curencyRate,
                discountTotal: data.discountTotal,
                discountPercent: data.discountPercent,
                shippingAddressStreet: data.shippingAddressStreet,
                shippingAddressCity: data.shippingAddressCity,
                shippingAddressBuilding: data.shippingAddressBuilding,
                shippingAddressCountry: data.shippingAddressCountry,
                shippingAddressDate: data.shippingAddressDate,
                synced: false,
            });
            let s = status.map((v, k) => {
                if (data.status == v.value)
                    return v;
            });
            let pm = paymentMethod.map((v, k) => {
                // console.log('v', v);
                if (data.paymentMethod == v.value)
                    return v;
                // return v[0];
            });
            // console.log('data.paymentMethod pm', pm);
            // console.log('data.paymentMethod pm[0]', pm[0]);
            // console.log('data.paymentMethod', data.paymentMethod);
            let c = curency.map((v, k) => {
                if (data.curency == v.value)
                    return v;
            });
            setSelectItems({
                ...selectItems,
                // account: {
                // label: data.account.name,
                //     value: data.account.id,
                // },
                // agreement: {
                // label: data.agreement.name,
                // value: data.agreement.id,
                // },
                status: s,
                // paymentMethod: pm,
                paymentMethod: pm[0],
                curency: c,
            });
        } else {
            setValues({
                ...values,
                manager: (typeof userData == 'object' && userData.id != undefined) ? `/api/users/${userData.id}` : ''
            });
        }
    }, [data]);

    // При створенні контрагента в модальному вікні відразу заповнити поля
    useEffect(() => {
        if (newAccountModal !== "") {
            setValues({
                ...values,
                account: (newAccountModal.id != undefined) ? `/api/accounts/${newAccountModal.id}` : '',
            });
            setSelectItems({
                ...selectItems,
                account: {
                    label: newAccountModal.name,
                    value: newAccountModal.id
                },
            });
            // Обнулити адреси
            // cleanAddress();
        }
    }, [newAccountModal]);

    // При створенні договору в модальному вікні відразу заповнити поля
    useEffect(() => {
        if (newAgreementModal !== "") {
            setValues({
                ...values,
                agreement: (newAgreementModal.id != undefined) ? `/api/agreements/${newAgreementModal.id}` : '',
                paymentMethod: (newAgreementModal.typePayment != undefined) ? newAgreementModal.typePayment : '',

            });
            setSelectItems({
                ...selectItems,
                agreement: {
                    label: newAgreementModal.name,
                    value: newAgreementModal.id
                },
            });
        }
    }, [newAccountModal, newAgreementModal]);


    // Додати адресу до контрагента для створення нової адреси для контрагента
    useEffect(() => {
        setNewAddress({
            ...newAddress,
            name: printedAddress
        });
    }, [printedAddress]);

    const handleInputChange = (e) => {
        // console.log(e);
        const { name, value, type } = e.target;
        setValues({
            ...values,
            [name]: type === "number" ? parseFloat(value, 10) : value
        });
    };

    // Ф-ція для зміни адреси, введеної вручну
    const handleInputAddressChange = (event) => {
        setPrintedAddress(event.target.value);
    };

    // const [showModal, setShowModal] = useState(true);
    const [showModalCreateAccount, setShowModalCreateAccount] = useState(true);

    const handleSubmit = event => {
        event.preventDefault();
        let v = true;
        let vError = '';

        // if (values.account == '') {
        //     v = false;
        //     vError += "-> Контрагент\n";
        // }
        // if (values.agreement == '') {
        //     v = false;
        //     vError += "-> Договір\n";
        // }
        if (values.status == '') {
            v = false;
            vError += "-> Статус\n";
        }
        if (values.paymentMethod == '') {
            v = false;
            vError += "-> Метод оплати\n";
        }
        if (values.curency == '') {
            v = false;
            vError += "-> Валюта\n";
        }

        if (!v) {
            alert("Ви не заповнили обовязкові поля:\n" + vError);
        }

        if (v && editorRef.current) {
            // console.log(editorRef.current.getContent());
            // setValues({
            //     ...values,
            //     // ['description']: editorRef.current.getContent(),
            //     // ['account']: (typeof values.account != 'string')? `/api/accounts/${values.account}`: values.account
            // });
            if (values?.account) {
                values.account = (typeof values.account != 'string') ? `/api/accounts/${values.account}` : values.account;
            }
            if (values?.agreement) {
                values.agreement = (typeof values.agreement != 'string') ? `/api/agreements/${values.agreement}` : values.agreement;
            }
            values.manager = (typeof userData == 'object' && userData.id != undefined) ? `/api/users/${userData.id}` : ''
            // const article = values;

            // Додати опис до замовлення
            values.description = editorRef.current.getContent();

            if (data != undefined && isUpdateOrder) {
                AxiosInstance.put(`${urlApi}/${data.id}`, values).then((response) => {
                    if (response.status == 200) {
                        props.setMyData(response.data);
                        // Popup про успішні зміни
                        props.toastifySuccess(popupTextPutSuccess);
                        // Закрити модальне вікно оновлення загальних даних
                        props.setShowModal(false);
                    }
                });
            } else if (isUpdateOrder) {
                AxiosInstance.post(urlApi, values).then((response) => {
                    if (response.status == 201) {
                        // Popup про успішні зміни
                        props.toastifySuccess(popupTextPostSuccess);
                        return setTimeout(() => {
                            props.setShow(true);
                            return navigate(`/orders/update/${response.data.id}`);
                        }, 2000);
                    }
                });
            };

            // Додати введену адресу для контрагента
            if (values?.account && checked && selectItems.account != undefined) {
                AxiosInstance.post(urlApi_2, newAddress).then((response) => {
                    // console.log('response newFddress', response);
                    if (response.status == 201) {
                        // console.log("Нову адресу додано");
                    } else {
                        console.log("Нову адресу не додано");
                    };
                });
            };
        };
    };

    // Контрагенти, які приписані до менеджера
    const [defaultAccountManager, setDefaultAccountManager] = useState({});
    // Договори, які приписані до контрагента
    const [defaultAgreement, setDefaultAgreement] = useState({});
    // Який тип оплати до обреного договору
    const [typePayment, setTypePayment] = useState("");

    useEffect(() => {
        loadOptionsAcc('');
    }, []);


    /** Контрагенти */
    // const [selectedOption, setSelectedOption] = useState(null);
    const loadOptionsAcc = async (inputValue) => {
        let query = `name=${inputValue}`;
        if (inputValue == '' && role == 'ROLE_MANAGER')
            query = `manager.id=${userData.id}`;
        if (inputValue != '' && role == 'ROLE_MANAGER')
            query += `&manager.id=${userData.id}`;
        if (inputValue == '' && role == 'ROLE_ADMIN')
            return {};

        let rez = await AxiosInstance.get(`/accounts?${query}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log("response", response);
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
        setDefaultAccountManager(rez);

        return rez;
    };

    // Завантажити договори
    const loadOptionsAgree = async (inputValue) => {
        // console.log('inputValue', inputValue);
        let query = `name=${inputValue}`;
        if (inputValue == '' && role == 'ROLE_MANAGER')
            query = `account.manager=${userData.id}`;
        if (selectItems.account != undefined && selectItems.account.value != undefined) {
            query = `&account.id=${selectItems.account.value}`;
        }

        let rez = await AxiosInstance.get(`/agreements?${query}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log("response", response);
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
                typePayment: result.typePayment
            }));
        });
        setDefaultAgreement(rez);

        return rez;
    };

    // Відслідковувати зміни типу оплати
    useEffect(() => {
        // console.log('typePayment', typePayment);
        // const paymentMethod = [
        //     { value: 'cash', label: 'Готівка' },
        //     { value: 'bank', label: 'Банківський переказ' },
        // ]

        if (typePayment === "kasa") {
            // console.log("typePayment === kasa");
            handleChange(paymentMethod[0], 'paymentMethod');
        } else if (typePayment === "bank") {
            // console.log("typePayment === bank");
            handleChange(paymentMethod[1], 'paymentMethod');
        } else {
            handleChange(paymentMethod[1], 'paymentMethod');
        }
    }, [typePayment]);

    const handleChange = (value, name) => {
        // console.log('value', value);
        // console.log('name', name);

        // Заповнити значення типу оплати
        if (name === "agreement") {
            setTypePayment(value.typePayment);
        };

        // Видалити тимчасове значення (потрібно для автоматичного вибору типу оплати при виборі договору)
        delete value.typePayment;

        setValues({
            ...values,
            [name]: value.value
        });
        setSelectItems({
            ...selectItems,
            [name]: value
        });
    };

    // Для dateOrder
    const [selectedDate, setSelectedDate] = useState(new Date());
    // Викликаємо onChange при старті сторінки з поточною датою
    useEffect(() => {
        datePicker(selectedDate);
    }, []);

    const datePicker = (value) => {
        let formattedDate = value.toISOString().slice(0, 10);
        // console.log(typeof formattedDate);
        // values['dateOrder'] = formattedDate;
        // console.log('formattedDate', formattedDate);
        let name = 'dateOrder';
        setValues({
            ...values,
            [name]: formattedDate
        });
        // console.log("datePicker", values);
    };

    const actionShowModal = (rez) => {
        // setShowModal(rez);
        setShowModalCreateAccount(rez);
        if (rez != true)
            console.log("Modal hide");
    };

    // Дункція для дозволу створення замовлення
    const allowUpdateOrder = () => {
        setTimeout(() => {
            setIsUpdateOrder(true);
        }, 1000);
    };

    return (
        <>
            <CForm onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col' style={{ zIndex: "101", position: "relative" }}>
                        <div className='row'>
                            {/* <div className='col'> */}
                            <div className='col d-flex justify-content-between'>
                                <CFormLabel className='mb-2' htmlFor="exampleFormControlInput1">Контрагент</CFormLabel>
                                {
                                    // (data != undefined && data.account == undefined) &&
                                    <div style={{ height: "30px", display: "flex", alignItems: "flex-end", zIndex: "99", position: "relative" }}>
                                        <BasicModal
                                            // show={showModal}
                                            show={showModalCreateAccount}
                                            actionShowModal={actionShowModal}
                                            title={'Новий контрагент'}
                                            content={
                                                <div style={{ height: '600px', overflowY: 'auto' }}>
                                                    <AccountsFormComponents data={data} type="create"
                                                        setShowModalCreateAccount={setShowModalCreateAccount}
                                                        setNewAccountModal={setNewAccountModal} setNewAgreementModal={setNewAgreementModal}
                                                        setIsUpdateOrder={setIsUpdateOrder} allowUpdateOrder={allowUpdateOrder}
                                                    />
                                                </div>
                                            }
                                            btn_name="Створити"
                                            btn_color="link"
                                            btn_title="Створити контрагента"
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptionsAcc}
                            // defaultOptions
                            value={selectItems.account}
                            // onChange={handleChange}
                            className="flex-grow-1"
                            name="account"
                            defaultOptions={defaultAccountManager}
                            onChange={(value) => { handleChange(value, 'account') }}
                            classNamePrefix='account'
                            styles={{ border: '1px solid red' }}
                        />
                    </div>
                    <div className='col' style={{ zIndex: "101", position: "relative" }}>
                        <CFormLabel htmlFor="exampleFormControlInput1">Договір</CFormLabel>
                        {/* <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptionsAgree}
                            // defaultOptions
                            value={selectItems.agreement}
                            // onChange={handleChange}
                            className="flex-grow-1"
                            name="account"
                            // defaultOptions={defaultAgreement}
                            onChange={(value) => { handleChange(value, 'agreement') }}
                            classNamePrefix='account'
                            styles={{ border: '1px solid red' }}
                        /> */}
                        <Select
                            options={defaultAgreement}
                            value={selectItems.agreement} onChange={(value) => { handleChange(value, 'agreement') }} placeholder="Виберіть договір" />
                    </div>
                </div>
                <hr />
                <div className='row'>
                    {/* <div className='col'>

                    </div> */}
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Статус замовлення</CFormLabel>
                        <Select options={status} value={selectItems.status} onChange={(value) => { handleChange(value, 'status') }} isDisabled={true} />
                    </div>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Метод оплати</CFormLabel>
                        <Select options={paymentMethod} value={selectItems.paymentMethod} onChange={(value) => { handleChange(value, 'paymentMethod') }} isDisabled />
                    </div>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Дата виконання</CFormLabel>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker 
                                value={values.dateOrder} 
                                onChange={datePicker}  
                                inputFormat="yyyy-MM-dd"  
                                // renderInput={(params) => <TextField {...params} />} 
                            />
                        </LocalizationProvider> */}
                        <DatePicker
                            className="form-control"
                            dateFormat="yyyy-MM-dd"
                            selected={(values.dateOrder == null) ? new Date() : new Date(values.dateOrder)}
                            onChange={(date) => {
                                setSelectedDate(date);
                                datePicker(date)
                            }}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col sum-inner'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Сума</CFormLabel>
                        <CFormInput type="number" id="exampleFormControlInput2" name='sumOrdered' disabled={true} value={values.sumOrdered} />
                    </div>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Курс</CFormLabel>
                        <CFormInput type="number" id="exampleFormControlInput2" name='curencyRate' value={values.curencyRate} onChange={handleInputChange} />
                    </div>
                    <div className='col order-input' style={{ zIndex: "100", position: "relative" }}>
                        <CFormLabel htmlFor="exampleFormControlInput1" name='curency' value={values.curency} onChange={handleInputChange}>Валюта</CFormLabel>
                        <Select options={curency} value={selectItems.curency} onChange={(value) => { handleChange(value, 'curency') }} />
                    </div>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Знижка %</CFormLabel>
                        <CFormInput type="number" id="exampleFormControlInput2" name='dateOrder' value={values.dateOrder == null ? '' : values.dateOrder} onChange={handleInputChange} />
                    </div>
                </div>

                {/* Водобжати вибір адреси тільки після вибору контрагента */}
                {
                    (selectItems.account != undefined && selectItems.account.value != undefined && selectItems.account.value != "") &&
                    <div className='row mt-3'>
                        <div>
                            <div className='col'>
                                <CFormCheck type="checkbox" label="Ввести адресу вручну" checked={checked} onChange={() => setChecked(!checked)} />
                            </div>
                            {
                                (!checked) ?
                                    <div className='col'>
                                        <CFormLabel htmlFor="exampleFormControlInput1">Адреса контрагента*</CFormLabel>
                                        <CFormSelect id=""
                                            onChange={handleSelectChangeAddress}
                                            // value={(selectedAddress !== "") ? selectedAddress : 0}
                                            required
                                        >
                                            <option value="">--Виберіть елемент--</option>
                                            {
                                                (typeof address == "object") &&
                                                address.map(el => {
                                                    return <option key={el.id} value={el.name}>{el.name}</option>
                                                })
                                            }
                                        </CFormSelect>
                                    </div>

                                    :
                                    <div className='col'>
                                        <CFormLabel htmlFor="exampleFormControlInput1">Адреса введена вручну*</CFormLabel>
                                        <CFormInput type="address" id="exampleFormControlInput2" name='shippingAddressCity' value={printedAddress} onChange={handleInputAddressChange} required />
                                    </div>
                            }
                        </div>

                        {/* <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Країна</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput2" name='shippingAddressCountry' value={values.shippingAddressCountry} onChange={handleInputChange} />
                    </div>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Місто</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput2" name='shippingAddressCity' value={values.shippingAddressCity} onChange={handleInputChange} />
                    </div>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Вулиця</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput2" name='shippingAddressStreet' value={values.shippingAddressStreet} onChange={handleInputChange} />
                    </div>
                    <div className='col'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Будинок</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput2" name='shippingAddressBuilding' value={values.shippingAddressBuilding} onChange={handleInputChange} />
                    </div> */}
                    </div>
                }

                <div className='row' style={{ zIndex: "1", position: "relative" }}>
                    <div className='col'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Опис</CFormLabel>

                            <Editor
                                apiKey={apiKey}
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={(values != undefined) ? values.description : ''}
                                init={{
                                    height: 500,
                                    // menubar: false,
                                    menubar: 'edit view insert format tools',
                                    // plugins: [
                                    //   'advlist autolink lists link image charmap print preview anchor',
                                    //   'searchreplace visualblocks code fullscreen',
                                    //   'insertdatetime media table paste code help wordcount'
                                    // ],
                                    plugins: [
                                        'advlist', 'autolink',
                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                        'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'code'
                                    ],
                                    // toolbar: 'undo redo | formatselect | ' +
                                    //   'bold italic backcolor | alignleft aligncenter ' +
                                    //   'alignright alignjustify | bullist numlist outdent indent | ' +
                                    //   'removeformat | help',
                                    toolbar: 'undo redo | blocks fontname fontfamily fontsize | bold italic underline strikethrough superscript subscript | ' +
                                        'backcolor forecolor |' +
                                        'alignleft aligncenter alignright alignjustify | ' +
                                        'bullist numlist checklist outdent indent | removeformat | table help',
                                    font_size_formats: '10px 12px 14px 16px 18px 24px 36px 48px 72px',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </div>
                    </div>
                </div>
                <CButton type="submit" color="success" title="Зберегти дані замовлення">Зберегти</CButton>

                {
                    (props.type != undefined && props.type === "modal") &&
                    <CButton className="ms-3" color="secondary" onClick={() => props.setShowModal(false)} title="Закрити вікно оновлення загальних даних замовлення">Закрити</CButton>
                }
            </CForm>
        </>
    );
};

export default FormOrders;