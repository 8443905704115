import React, { useState } from 'react';
import { CForm } from '@coreui/react';
import axios from "axios";
import AxiosInstance from 'src/components/Axios';
import { confirmAlert } from 'react-confirm-alert'; // Import

const tokenStr = localStorage.getItem('user');

// Видалення зображення з продукту
const DeleteImgForm = ({ product_id, actionShowModal, media, UpdateMedia, setShowModalMedia, toastifySuccess, mainMedia, resultMedias }) => {
  // console.log("DeleteImgFrom media", media);

  const urlApi = '/products';
  const [validated, setValidated] = useState(false);
  // Текст для popup
  const popupTextMediaSuccess = "Зображення видалено";

  const handleSubmit = event => {
    event.preventDefault()
    event.preventDefault();

    let v = false;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation()
    } else {
      v = true;
    }
    setValidated(true);
    if (v) {
      const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects';
      const formData = new FormData();
      if (product_id != undefined)
        formData.append('product', product_id);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": `Bearer ${tokenStr}`
        },
      };
      axios.post(url, formData, config).then((response) => {
        console.log(response.data);
        if (actionShowModal != undefined) {
          actionShowModal(false);
        }
      });
    }
  }

  // Закрити форму видалення і відкрити форму підтвердження видалення
  const openDeleteForm = (id) => {
    // Закрити модальне вікно
    setShowModalMedia(false);
    // Видалити зображення продукта
    Deleted(id);
  }

  // Видалити зображення продукта
  function Deleted(id) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            // console.log('mainMedia', mainMedia);
            // console.log('id', id);

            if (mainMedia != undefined && mainMedia != null && mainMedia != false && (mainMedia.id === id)) {
              AxiosInstance.put(`${urlApi}/${product_id}`, { mainMedia: null }).then((response) => {
                console.log('видалення головного зображення')
                // console.log('response (mainMedia: null)', response);
                if (response.status === 200) {

                  AxiosInstance.delete('/media_objects/' + id).then((response) => {
                    // console.log("response", response);
                    if (response.status === 204) {

                      // Запит на оновлення даних (зображень) продукту
                      UpdateMedia();
                      // Popup про успішні зміни
                      toastifySuccess(popupTextMediaSuccess);

                    }
                  });
                } else {
                  console.log("Помилка очищення головного зображення");
                }
              })
            } else {
              AxiosInstance.delete('/media_objects/' + id).then((response) => {
                // console.log("response delete media_object", response);
                if (response.status === 204) {
                  // Запит на оновлення даних (зображень) продукту
                  UpdateMedia();
                  // Popup про успішні зміни
                  toastifySuccess(popupTextMediaSuccess);
                } else {
                  console.log("Помилка видалення зображення");
                }
              });
            }
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  }

  return (
    <>
      <CForm onSubmit={handleSubmit} noValidate validated={validated} >
        <div className="my-4">
          Виберіть зображення для видалення
        </div>
        <div
          // className='d-flex justify-content-center'
          className='d-flex justify-content-start'
        >
          {
            (media != '') &&
            // media.
            // [...media].
            //   sort((a, b) => (a.sort || 0) - (b.sort || 0)).
            resultMedias.
              map(el => {
                return (
                  <div key={el.id} className="me-2">
                    <img
                      className="me-3"
                      src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.contentUrl : ''}
                      style={{ height: '100px', width: 'auto', cursor: "pointer" }}
                      onClick={() => openDeleteForm(el.id)}
                    />
                  </div>
                )
              })
          }
        </div>
      </CForm>
    </>
  );
}

export default DeleteImgForm;