// Функція для вибору зображення відповідного розміру
const useSelectImageSize = (imgObj, size) => {
   // console.log('imgObj', imgObj);
   // console.log('size', size);

   // Зображення відповідного розміру
   let imgObjSize = [];
   // Шлях до зображення відповідного розміру
   let contentUrl = "";

   if (imgObj?.resizeImagings != undefined && size != undefined) {
      imgObjSize = imgObj.resizeImagings.filter(item => item.size === size);
   };
   // console.log('imgObjSize', imgObjSize);

   if (imgObjSize.length > 0) {
      contentUrl = imgObjSize[0].contentUrl;
   } else {
      // console.log("Зображення заданого розміру відсутнє, тому відображає наявний розмір зображення");
      // Заповнити шлях до зображення для відображення на сайті
      // return `${process.env.NEXT_PUBLIC_SERVER_URL}${imgObj.contentUrl}`;
      return `${process.env.NEXT_PUBLIC_SERVER_URL}${encodeURI(imgObj?.contentUrl)}`;
   }
   // console.log('contentUrl', contentUrl);

   // Заповнити шлях до зображення для відображення на сайті
   // return `${process.env.NEXT_PUBLIC_SERVER_URL}${imgObj.contentUrl}`;
   return `${process.env
      // .NEXT_PUBLIC_SERVER_URL
      .REACT_APP_SERVER_URL
      }${encodeURI(contentUrl)}`;
};
export default useSelectImageSize
