import React from 'react';

import News, { LoaderNews } from './views/news/News';
import NewsForm, { LoaderNewsUpdate } from './views/news/NewsForm';

import Banners, { LoaderBanners } from './views/banners/Banners';
import BannersForm, { LoaderBannersUpdate } from './views/banners/BannersForm';

import Pages, { LoaderPages } from './views/site-page/Pages';
import PagesForm, { LoaderPagesUpdate } from './views/site-page/PagesForm';

import Blog, { LoaderBlog } from './views/blog/Blog';
import BlogForm, { LoaderBlogUpdate } from './views/blog/BlogForm';

import Menu, { LoaderMenu } from './views/menu/Menu';
import MenuForm, { LoaderMenuUpdate } from './views/menu/MenuForm';

import Media, { LoaderMedia } from './views/media/Media';

import Options, { LoaderOptions } from './views/options/Options';
import OptionForm, { LoaderOptionUpdate } from './views/options/OptionForm';

import Products, { LoaderProducts } from './views/products/Products';
import ProductForm, { LoaderProductsUpdate } from './views/products/ProductForm';
import ProductInformation, { LoaderProductInformation } from './views/products/ProductInformation';

import SiteProducts, {LoaderSiteProducts} from './views/site-products/SiteProducts';
import SiteProductForm, {LoaderSiteProductsUpdate} from './views/site-products/SiteProductForm';
import Slider, { LoaderSlider } from './views/slider/Slider';
import SliderForm, { LoaderSliderUpdate } from './views/slider/SliderForm';

import Category, { LoaderCategory } from './views/category/Category';
import CategoryForm, { LoaderCategoryUpdate } from './views/category/CategoryForm';

import Users, { LoaderUsers } from './views/users/Users';
import UsersForm, { LoaderUsersUpdate } from './views/users/UsersForm';

import Accounts, { LoaderAccounts } from './views/accounts/Accounts';
import AccountsForm, { LoaderAccountsUpdate } from './views/accounts/AccountsForm';

import MeasurmentUnit, { LoaderMeasurmentUnit } from './views/measurment-unit/MeasurmentUnit';
import MeasurmentUnitForm, { LoaderMeasurmentUnitUpdate } from './views/measurment-unit/MeasurmentUnitForm';

import Storage, { LoaderStorage } from './views/storage/Storage';
import StorageForm, { LoaderStorageUpdate } from './views/storage/StorageForm';

import Orders, { LoaderOrders } from './views/orders/Orders';
import OrdersForm, { LoaderOrdersUpdate } from './views/orders/OrdersForm';
import OrderPrint, { LoaderOrderPrint } from './views/orders/OrderPrint';

import PreOrders, { LoaderPreOrders } from './views/pre_orders/PreOrders';
import PreOrdersForm, { LoaderPreOrdersUpdate } from './views/pre_orders/PreOrdersForm';

import Agreements, { LoaderAgreements } from './views/agreements/agreements';
import AgreementId, { LoaderAgreementId } from './views/agreements/agreementId';

import Attributes, { LoaderAttributes } from './views/attributes/attributes';
import AttributesForm, { LoaderAttributesUpdate } from './views/attributes/attributesForm';
import VariationsForm, { LoaderVariationsUpdate } from './views/attributes/variationsForm';
import Home from './views/home/Home';

import Statistics from './views/statistics/Statistics';


import Faq, { LoaderFaq } from './views/faq/faq';
import FaqForm, { LoaderFaqUpdate } from './views/faq/faqForm';

import PaymentMethods, {LoaderPaymentMethods} from './views/payment-methods/PaymentMethods';
import PaymentMethodsForm, {LoaderPaymentMethodsUpdate} from './views/payment-methods/PaymentMethodsForm';


import Coupons, { LoaderCoupons } from './views/coupons/coupons';
import CouponsForm, { LoaderCoponsUpdate } from './views/coupons/couponsForm';

import Fees, { LoaderFees } from './views/fees/Fees';
import FeesForm, { LoaderFeesUpdate } from './views/fees/FeesForm';

import StorageElement, { LoaderStorageElement } from './views/storage-element/StorageElement';
import StorageElementForm, { LoaderStorageElementUpdate } from './views/storage-element/StorageElementForm';


import Location, { LoaderLocation } from './views/location/Location';
import LocationForm, { LoaderLocationUpdate } from './views/location/LocationForm';

import StorageBalance, { LoaderStorageBalance } from './views/storage-balance/StorageBalance';

import Prices from './views/products/Prices';

import UsefulMaterials, { LoaderUsefulMaterials } from './views/useful-materials/UsefulMaterials';
import UsefulMaterialForm, { LoaderUsefulMaterialUpdate } from './views/useful-materials/UsefulMaterialForm';
import UsefulMaterialInformation, { LoaderUsefulMaterialInformation } from './views/useful-materials/UsefulMaterialInformation';

import CreatedForms, { LoaderCreatedForms } from './views/builder-forms/CreatedForms';
import BuilderForms, { LoaderBuilderForms } from './views/builder-forms/BuilderForms';
import FormsAnswer, { LoaderFormsAnswer } from './views/form-answer/FormsAnswer';
import FormAnswerItem, { LoaderFormAnswerItem } from './views/form-answer/FormAnswerItem';

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Colors = React.lazy(() => import('./views/theme/colors/Colors'))
const Typography = React.lazy(() => import('./views/theme/typography/Typography'))




// Base
const Accordion = React.lazy(() => import('./views/base/accordion/Accordion'))
const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'))
const Cards = React.lazy(() => import('./views/base/cards/Cards'))
const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'))
const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'))
const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'))
const Navs = React.lazy(() => import('./views/base/navs/Navs'))
const Paginations = React.lazy(() => import('./views/base/paginations/Paginations'))
const Placeholders = React.lazy(() => import('./views/base/placeholders/Placeholders'))
const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'))
const Progress = React.lazy(() => import('./views/base/progress/Progress'))
const Spinners = React.lazy(() => import('./views/base/spinners/Spinners'))
const Tables = React.lazy(() => import('./views/base/tables/Tables'))
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'))

// Buttons
const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'))
const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'))
const Dropdowns = React.lazy(() => import('./views/buttons/dropdowns/Dropdowns'))

//Forms
const ChecksRadios = React.lazy(() => import('./views/forms/checks-radios/ChecksRadios'))
const FloatingLabels = React.lazy(() => import('./views/forms/floating-labels/FloatingLabels'))
const FormControl = React.lazy(() => import('./views/forms/form-control/FormControl'))
const InputGroup = React.lazy(() => import('./views/forms/input-group/InputGroup'))
const Layout = React.lazy(() => import('./views/forms/layout/Layout'))
const Range = React.lazy(() => import('./views/forms/range/Range'))
const Select = React.lazy(() => import('./views/forms/select/Select'))
const Validation = React.lazy(() => import('./views/forms/validation/Validation'))

const Charts = React.lazy(() => import('./views/charts/Charts'))

// Icons
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'))
const Flags = React.lazy(() => import('./views/icons/flags/Flags'))
const Brands = React.lazy(() => import('./views/icons/brands/Brands'))

// Notifications
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'))
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'))
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'))
const Toasts = React.lazy(() => import('./views/notifications/toasts/Toasts'))

const Widgets = React.lazy(() => import('./views/widgets/Widgets'))
const Login = React.lazy(() => import('./views/pages/login/Login'))



/** ASD ROUTERS */
// const NewsForm = React.lazy(() => import('./views/news/NewsForm'))

const routes = [
  { path: '/', exact: true, name: 'Home', element: <Home /> },
  { path: '/dashboard', name: 'Dashboard', element: <Dashboard /> },
  { path: '/theme', name: 'Theme', element: Colors, exact: <true /> },
  { path: '/theme/colors', name: 'Colors', element: <Colors /> },
  { path: '/theme/typography', name: 'Typography', element: <Typography /> },
  { path: '/base', name: 'Base', element: Cards, exact: <true /> },
  { path: '/base/accordion', name: 'Accordion', element: <Accordion /> },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', element: <Breadcrumbs /> },
  { path: '/base/cards', name: 'Cards', element: <Cards /> },
  { path: '/base/carousels', name: 'Carousel', element: <Carousels /> },
  { path: '/base/collapses', name: 'Collapse', element: <Collapses /> },
  { path: '/base/list-groups', name: 'List Groups', element: <ListGroups /> },
  { path: '/base/navs', name: 'Navs', element: <Navs /> },
  { path: '/base/paginations', name: 'Paginations', element: <Paginations /> },
  { path: '/base/placeholders', name: 'Placeholders', element: <Placeholders /> },
  { path: '/base/popovers', name: 'Popovers', element: <Popovers /> },
  { path: '/base/progress', name: 'Progress', element: <Progress /> },
  { path: '/base/spinners', name: 'Spinners', element: <Spinners /> },
  { path: '/base/tables', name: 'Tables', element: <Tables /> },
  { path: '/base/tooltips', name: 'Tooltips', element: <Tooltips /> },
  { path: '/buttons', name: 'Buttons', element: Buttons, exact: <true /> },
  { path: '/buttons/buttons', name: 'Buttons', element: <Buttons /> },
  { path: '/buttons/dropdowns', name: 'Dropdowns', element: <Dropdowns /> },
  { path: '/buttons/button-groups', name: 'Button Groups', element: <ButtonGroups /> },
  { path: '/charts', name: 'Charts', element: <Charts /> },
  { path: '/forms', name: 'Forms', element: FormControl, exact: <true /> },
  { path: '/forms/form-control', name: 'Form Control', element: <FormControl /> },
  { path: '/forms/select', name: 'Select', element: <Select /> },
  { path: '/forms/checks-radios', name: 'Checks & Radios', element: <ChecksRadios /> },
  { path: '/forms/range', name: 'Range', element: <Range /> },
  { path: '/forms/input-group', name: 'Input Group', element: <InputGroup /> },
  { path: '/forms/floating-labels', name: 'Floating Labels', element: <FloatingLabels /> },
  { path: '/forms/layout', name: 'Layout', element: <Layout /> },
  { path: '/forms/validation', name: 'Validation', element: <Validation /> },
  { path: '/icons', exact: true, name: 'Icons', element: <CoreUIIcons /> },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', element: <CoreUIIcons /> },
  { path: '/icons/flags', name: 'Flags', element: <Flags /> },
  { path: '/icons/brands', name: 'Brands', element: <Brands /> },
  { path: '/notifications', name: 'Notifications', element: Alerts, exact: <true /> },
  { path: '/notifications/alerts', name: 'Alerts', element: <Alerts /> },
  { path: '/notifications/badges', name: 'Badges', element: <Badges /> },
  { path: '/notifications/modals', name: 'Modals', element: <Modals /> },
  { path: '/notifications/toasts', name: 'Toasts', element: <Toasts /> },
  { path: '/widgets', name: 'Widgets', element: <Widgets /> },
  /** ASD Routers */
  { path: '/news', name: 'Новини', element: <News />, loader: LoaderNews },
  { path: '/news/create', name: 'Створити новини', element: <NewsForm />, loader: LoaderNewsUpdate },
  { path: '/news/update/:newsId', name: 'Редагувати новини', element: <NewsForm />, loader: LoaderNewsUpdate },

  { path: '/banners', name: 'Банери', element: <Banners />, loader: LoaderBanners },
  { path: '/banners/create', name: 'Створити банер', element: <BannersForm />, loader: LoaderBannersUpdate },
  { path: '/banners/update/:newsId', name: 'Редагувати банер', element: <BannersForm />, loader: LoaderBannersUpdate },

  { path: '/site-pages', name: 'Сторінки', element: <Pages />, loader: LoaderPages },
  { path: '/site-pages/create', name: 'Створити сторінку', element: <PagesForm />, loader: LoaderPagesUpdate },
  { path: '/site-pages/update/:newsId', name: 'Редагувати сторінку', element: <PagesForm />, loader: LoaderPagesUpdate },

  { path: '/blog', name: 'Блог', element: <Blog />, loader: LoaderBlog },
  { path: '/blog/create', name: 'Створити блог', element: <BlogForm />, loader: LoaderBlogUpdate },
  { path: '/blog/update/:newsId', name: 'Редагувати блог', element: <BlogForm />, loader: LoaderBlogUpdate },

  { path: '/menu', name: 'Меню', element: <Menu />, loader: LoaderMenu },
  { path: '/menu/create', name: 'Створити меню', element: <MenuForm />, loader: LoaderMenuUpdate },
  { path: '/menu/update/:menuId', name: 'Редагувати меню', element: <MenuForm />, loader: LoaderMenuUpdate },
  { path: '/menu/children/:child_id', name: 'Редагувати меню', element: <MenuForm />, loader: LoaderMenuUpdate },

  { path: '/media', name: 'Медіа', element: <Media />, loader: LoaderMedia },

  { path: '/options', name: 'Налаштування', element: <Options />, loader: LoaderOptions },
  { path: '/options/create', name: 'Створити Налаштування', element: <OptionForm />, loader: LoaderOptionUpdate },
  { path: '/options/update/:optionId', name: 'Редагувати Налаштування', element: <OptionForm />, loader: LoaderOptionUpdate },

  { path: '/login', name: 'Логін', element: <Login /> },

  { path: '/products', name: 'Товари', element: <Products />, loader: LoaderProducts },
  { path: '/products/create', name: 'Створити Товар', element: <ProductForm />, loader: LoaderProductsUpdate },
  { path: '/products/update/:pid', name: 'Редагувати Товари', element: <ProductForm />, loader: LoaderProductsUpdate },
  { path: '/products/read/:pid', name: 'Перегляд товару', element: <ProductInformation />, loader: LoaderProductInformation },
  { path: '/products/prices', name: 'Список товарів з цінами', element: <Prices /> },

  { path: '/site-products', name: 'Товари на сайті', element: <SiteProducts />, loader: LoaderSiteProducts },
  { path: '/site-products/create', name: 'Створити Товар на сайті', element: <SiteProductForm />, loader: LoaderSiteProductsUpdate },
  { path: '/site-products/update/:pid', name: 'Редагувати Товар на сайті', element: <SiteProductForm />, loader: LoaderSiteProductsUpdate },

  { path: '/category', name: 'Категорії', element: <Category />, loader: LoaderCategory },
  { path: '/category/create', name: 'Створити категорію', element: <CategoryForm />, loader: LoaderCategoryUpdate },
  { path: '/category/update/:pid', name: 'Редагувати Категорію', element: <CategoryForm />, loader: LoaderCategoryUpdate },

  { path: '/users', name: 'Користувачі', element: <Users />, loader: LoaderUsers },
  { path: '/users/create', name: 'Створити користувача', element: <UsersForm />, loader: LoaderUsersUpdate },
  { path: '/users/update/:pid', name: 'Редагувати користувача', element: <UsersForm />, loader: LoaderUsersUpdate },

  { path: '/accounts', name: 'Контрагенти', element: <Accounts />, loader: LoaderAccounts },
  { path: '/accounts/create', name: 'Створити контрагента', element: <AccountsForm />, loader: LoaderAccountsUpdate },
  { path: '/accounts/update/:pid', name: 'Редагувати контрагента', element: <AccountsForm />, loader: LoaderAccountsUpdate },

  { path: '/measurment-unit', name: 'Одиниці виміру', element: <MeasurmentUnit />, loader: LoaderMeasurmentUnit },
  { path: '/measurment-unit/create', name: 'Створити Одиниці виміру', element: <MeasurmentUnitForm />, loader: LoaderMeasurmentUnitUpdate },
  { path: '/measurment-unit/update/:pid', name: 'Редагувати Одиниці виміру', element: <MeasurmentUnitForm />, loader: LoaderMeasurmentUnitUpdate },

  { path: '/storage', name: 'Склади', element: <Storage />, loader: LoaderStorage },
  { path: '/storage/create', name: 'Створити Склад', element: <StorageForm />, loader: LoaderStorageUpdate },
  { path: '/storage/update/:pid', name: 'Редагувати Склад', element: <StorageForm />, loader: LoaderStorageUpdate },

  { path: '/orders', name: 'Замовлення', element: <Orders />, loader: LoaderOrders },
  { path: '/orders/create', name: 'Створити замовлення', element: <OrdersForm />, loader: LoaderOrdersUpdate },
  { path: '/orders/update/:pid', name: 'Редагувати замовлення', element: <OrdersForm />, loader: LoaderOrdersUpdate },
  { path: '/orders/print/:id', name: 'Друкувати', element: <OrderPrint />, loader: LoaderOrderPrint },

  // { path: '/pre-orders', name: 'Попереднє Замовлення', element: <PreOrders />, loader: LoaderPreOrders },
  { path: '/pre-orders', name: 'Онлайн Замовлення', element: <PreOrders />, loader: LoaderPreOrders },
  // { path: '/pre-orders/create', name: 'Створити Попереднє замовлення', element: <PreOrdersForm />, loader: LoaderPreOrdersUpdate },
  { path: '/pre-orders/create', name: 'Створити Онлайн замовлення', element: <PreOrdersForm />, loader: LoaderPreOrdersUpdate },
  // { path: '/pre-orders/update/:pid', name: 'Редагувати Попереднє замовлення', element: <PreOrdersForm />, loader: LoaderPreOrdersUpdate },
  { path: '/pre-orders/update/:pid', name: 'Редагувати Онлайн замовлення', element: <PreOrdersForm />, loader: LoaderPreOrdersUpdate },

  { path: '/agreements', name: 'Договори', element: <Agreements />, loader: LoaderAgreements },
  { path: '/agreements/:pid', name: 'Договори Id', element: <AgreementId />, loader: LoaderAgreementId },

  { path: '/attributes', name: 'Атрибути', element: <Attributes />, loader: LoaderAttributes },
  { path: '/attributes/create', name: 'Створити атрибут', element: <AttributesForm />, loader: LoaderAttributesUpdate },
  { path: '/attributes/update/:pid', name: 'Редагувати атрибут', element: <AttributesForm />, loader: LoaderAttributesUpdate },
  { path: '/attributes/variations/update/:pid', name: 'Редагувати варіацію', element: <VariationsForm />, loader: LoaderVariationsUpdate },


  { path: '/statistics', name: 'Статистика', element: <Statistics /> },


  { path: '/coupons', name: 'Купони', element: <Coupons />, loader: LoaderCoupons },
  { path: '/coupons/create', name: 'Створити купон', element: <CouponsForm />, loader: LoaderCoponsUpdate },
  { path: '/coupons/update/:pid', name: 'Редагувати купон', element: <CouponsForm />, loader: LoaderCoponsUpdate },

  { path: '/fees', name: 'Збори', element: <Fees />, loader: LoaderFees },
  { path: '/fees/create', name: 'Створити збори', element: <FeesForm />, loader: LoaderFeesUpdate },
  { path: '/fees/update/:pid', name: 'Редагувати збори', element: <FeesForm />, loader: LoaderFeesUpdate },


  { path: '/storage-element', name: 'Елемент складу', element: <StorageElement />, loader: LoaderStorageElement },
  { path: '/storage-element/create', name: 'Елемент складу', element: <StorageElementForm />, loader: LoaderStorageElementUpdate },
  { path: '/storage-element/update/:pid', name: 'Елемент складу', element: <StorageElementForm />, loader: LoaderStorageElementUpdate },

  { path: '/locations', name: 'Локація', element: <Location />, loader: LoaderLocation },
  { path: '/locations/create', name: 'Локація', element: <LocationForm />, loader: LoaderLocationUpdate },
  { path: '/locations/update/:pid', name: 'Локація', element: <LocationForm />, loader: LoaderLocationUpdate },

  { path: '/stage-balance', name: 'Залишки', element: <StorageBalance />, loader: LoaderStorageBalance },
  { path: '/stage-balance/:pid', name: 'Залишки', element: <StorageBalance />, loader: LoaderStorageBalance },

  {
    path: '/useful-materials', name: 'Корисні матеріали', element: <UsefulMaterials />, loader: LoaderUsefulMaterials
  },
  { path: '/useful-materials/create', name: 'Створити корисний матеріал', element: <UsefulMaterialForm />, loader: LoaderUsefulMaterialUpdate },
  { path: '/useful-materials/update/:pid', name: 'Редагувати корисний матеріал', element: <UsefulMaterialForm />, loader: LoaderUsefulMaterialUpdate },
  { path: '/useful-materials/read/:pid', name: 'Перегляд корисного матеріалу', element: <UsefulMaterialInformation />, loader: LoaderUsefulMaterialInformation },

  { path: '/faq', name: 'FAQ', element: <Faq />, loader: LoaderFaq },
  { path: '/faq/create', name: 'Створити FAQ', element: <FaqForm />, loader: LoaderFaqUpdate },
  { path: '/faq/update/:pid', name: 'Редагувати FAQ', element: <FaqForm />, loader: LoaderFaqUpdate },

  { path: '/builder-forms', name: 'Форми/Мої форми', element: <CreatedForms />, loader: LoaderCreatedForms },
  { path: '/builder-forms/create', name: 'Створити форму', element: <BuilderForms />, loader: LoaderBuilderForms },
  { path: '/builder-forms/update/:pid', name: 'Редагувати форму', element: <BuilderForms />, loader: LoaderBuilderForms },

  { path: '/form-answer', name: 'Форми/Відповіді', element: <FormsAnswer />, loader: LoaderFormsAnswer },
  { path: '/form-answer/review/:pid', name: 'Відповідь', element: <FormAnswerItem />, loader: LoaderFormAnswerItem },

  { path: '/slider', name: 'Слайдер', element: <Slider />, loader: LoaderSlider },
  { path: '/slider/create', name: 'Створити слайдер', element: <SliderForm />, loader: LoaderSliderUpdate },
  { path: '/slider/update/:menuId', name: 'Редагувати слайдер', element: <SliderForm />, loader: LoaderSliderUpdate },
  { path: '/slider/children/:child_id', name: 'Редагувати слайдер', element: <SliderForm />, loader: LoaderSliderUpdate },

  { path: '/payment-methods', name: 'Методи Оплати', element: <PaymentMethods />, loader: LoaderPaymentMethods },
  { path: '/payment-methods/create', name: 'Створити Методи Оплати', element: <PaymentMethodsForm />, loader: LoaderPaymentMethodsUpdate },
  { path: '/payment-methods/update/:pid', name: 'Редагувати Методи Оплати', element: <PaymentMethodsForm />, loader: LoaderPaymentMethodsUpdate },
]

export default routes
