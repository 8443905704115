import React, { useEffect, useRef, useState } from 'react';
import { CButton, CForm, CFormLabel } from "@coreui/react";
import Select from 'react-select';
import AxiosInstance from '../Axios';


export default function ChangeParentCategory(props) {
   const [parentCategory, setParentCategory] = useState(props.data.parent !== undefined ? { value: props.data.parent.id, label: props.data.parent.name } : {});
   const [categories, setCategories] = useState([])
   const [updatedCategory, setUpdatedCategory] = useState({})
   const [isSubmitting, setIsSubmitting] = useState(false)
   const changeParentSuccess = "Батьківську категорію змінено"
   const changeParentError = "Батьківську категорію не змінено"

   const ApiParams = {
      'g[0]': '@context',
      'g[1]': '@id',
      'g[2]': '@type',
      'g[3]': 'cat:read',
      'g[4]': 'cat:categories',
      'g[5]': 'cat:media',
      'g[6]': 'cat:parent',
      'g[7]': 'cat:admin',
   }


   const handleSubmit = async (event) => {
      event.preventDefault();

      try {
         if (!parentCategory.value) {
            console.error("Категорія не вибрана.");
            return;
         }
         await changeParentCategory();

         console.log("Категорія успішно оновлена.");
      } catch (error) {
         console.error("Помилка при оновленні категоріі:", error);
      }
   }

   const getCategories = async () => {
      try {
         const categories = await AxiosInstance.get(`/categories`
            , {
               headers: { accept: 'application/json' },
               params:
               {
                  itemsPerPage: 1000,
                  'g[0]': 'cat:read',
                  'g[1]': 'cat:categories',
                  'g[2]': 'cat:media',
                  'g[3]': 'cat:parent',
               }
            });

         // console.log(categories.data)
         const formattedCategories = categories.data
            .filter(elem => elem.id !== props.data.id)
            .map((category) => ({
               value: category.id,
               label: category.name
            })).sort((a, b) => a.label.localeCompare(b.label));
         setCategories(formattedCategories)
         return formattedCategories;
      } catch (error) {
         console.error("Помилка при отриманні категорій:", error);

      }
   };

   const changeParentCategory = async () => {
      setIsSubmitting(true)
      try {
         const response = await AxiosInstance.patch(`/categories/${props.data.id}`, { parent: `/api/categories/${parentCategory.value}` }, {
            headers: { accept: 'application/ld+json' },
            params: ApiParams
         });
         props.toastifySuccess(changeParentSuccess)
         setUpdatedCategory(response.data)
         // props.setParentId(parentCategory.value)
         props.setMyData(response.data)
         // setParentCategory({
         //    value: response.data.parent?.id,
         //    label: response.data.parent?.name,
         // });
         console.log("response.data", response.data)
         return response.data;
      } catch (error) {
         props.toastifyWarning(changeParentError)
         console.error("Помилка при зміні  категоріі:", error);

      }
      finally {
         setIsSubmitting(false)
      }
   }

   const handleChangeCategory = (value) => {
      setParentCategory(value);
      console.log(value)
   };

   useEffect(() => {
      getCategories()
      if (props.data.parent?.id) {
         setParentCategory({ value: props.data.parent.id, label: props.data.parent.name })
      }
   }, [])
   useEffect(() => {
      if (props.data.parent?.id) {
         setParentCategory({ value: props.data.parent.id, label: props.data.parent.name })
      }
   }, [props.data.parent])
   // console.log('categories', categories)
   // console.log('props', props)
   // console.log("categoryValue", category.value)
   // console.log("categoryName", category.label)

   return (
      <div>
         <hr className='mb4' />
         <CForm onSubmit={handleSubmit}>
            <div className='row'>
               <div className='col'>
                  <div className="mb-3" style={{ zIndex: "1", position: "relative", width: "50%" }}>
                     <CFormLabel htmlFor="exampleFormControlInput1">Вибрати батьківську категорію</CFormLabel>
                     <Select
                        options={categories}
                        value={parentCategory}
                        onChange={(value) => { handleChangeCategory(value) }}
                        placeholder="Оберіть батьківську категорію"
                     />
                  </div>
               </div>
            </div>
            <CButton className="mb-3" type="submit" disabled={
               isSubmitting || (props.data.parent?.id === parentCategory?.value)} color="success">Зберегти </CButton>
         </CForm>
      </div >
   )
}